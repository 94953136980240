// -------------------------------
// IRESS Bank
// -------------------------------
// generic

$brand-primary:                     #3A1C46;
$border-radius-base:                0;

//navbar
$navbar-default-bg:                 #3A1C46;
$navbar-top-bg:                     #3A1C46;

// Headings
$h1-color:                          #3A1C46;

// Links
$link-color:                        #0073CD;

// Font
$text-color:                        #545454;
//$font-family-base:                  'Roboto', 'Helvetica Neue',Helvetica,Arial,sans-serif;

// Buttons
$btn-default-bg:                    #EFECEF;
$btn-primary-bg:                    #0073CD;

// Footer
$footer-bg:                         #3A1C46;
