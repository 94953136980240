body
{
  background-color: #f5f5f5;
  font-family: Segoe UI;
}

.App-header {
  flex-direction: column;
  font-size: 16pt;
  color: white;
  text-align: center;
  background-color:#3a1c46;
  font-weight: 200;
  padding: 22px;
  margin-top:0px;
}

.Section-header {
  flex-direction: column;
  font-size: 14px;
  color: #111;
  font-weight: 400;
  width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-block-end: 0px;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.PortfolioOwner-panel {
  padding-bottom:30px;
}

.PortfolioOwner-name {
  font-size: 14pt;
  padding-bottom:15px;
  font-weight: 400;
  color: #3a1c46;
}

.SelectAll {
  font-weight: 650;
  font-size:1rem;
}

.fe-account{
  width: 100% !important;
}

.App-progress-message {
  text-decoration: none;
  text-align: center;
  width:800px;
  margin-left: auto;
  margin-right: auto;      
  font-size:1.5rem;
  padding-top:100px;
  font-weight:200;
}

.Error {
  color: red;
}

.MuiCircularProgress-svg {
  color: #34193f;
}

.inline-div {
  margin-top: 10px;;
  display: -webkit-box;
}

.portfolioLine {
  display: -webkit-box;
  margin-top: 12px;
  word-break: break-all;
  width: 95%;
}

.ProgressPanel{
  padding-top: 100px;
}

.Inline-LinkButton{
  padding-right: 0;
  padding-left: 0;
  margin-bottom: 0;
}

.form-group.form-inline>*, .form-group.form-join>*,
.form-group.form-inline>.i-label:not([for]) .i-label__t t, .form-group.form-inline>.i-label[for], .form-group.form-join>.i-label:not([for]) .i-label__text, .form-group.form-join>.i-label[for] {
  margin-right: 0;
}

.i-input.form-control, .i-input.form-control:focus {
  /* background-color: #f5f5f5; */
  border-bottom: 1pt solid #3a1c46;
  font-size:12pt;
}

.i-btn.btn-primary, .i-dropdown-toggle.btn-primary, .btn-primary
{
  background-color: #483052 !important;
  border: 1pt solid #483052 !important;
  color:#dcdcdc;
  padding-top:15px;
  padding-bottom: 30px;
}

.i-btn.btn-primary:hover, .i-btn.btn-primary:active
.i-dropdown-toggle.btn-primary:hover, .i-dropdown-toggle.btn-primary:active
.btn-primary:hover, .btn-primary:active
{
  background-color: #3a1c46 !important;
  border: 1pt solid #3a1c46 !important;
  color: #fff;
}

.ExportButton {
  flex-direction: column;
  font-size: 14px;
  color: #111;
  font-weight: 400;
  width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-block-end: 0px;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.ExportButton div {
  float:right;
}

.btn-label {
  text-align: right;
  float: right;
  font-size: 15px;
  font-weight: 200;
}

label {
  margin-bottom:0;
}