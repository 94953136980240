$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';
$fa-font-path: '~font-awesome/fonts';

@import './styles/_before-brand';

// Minimal theme
// =====================

@import './styles/dummy-brands/iress_bank';
@import '~@iress/client-portal-themes/dist/minimal/_variables';
$fa-font-path: '~font-awesome/fonts'; /* Dev file path  */
$roboto-font-path: '~roboto-fontface/fonts'; /* Dev file path  */
@import './styles/_after-brand';
@import '~@iress/client-portal-themes/dist/minimal/_core';
@import './styles/theme-overrides/minimal/_theme';


// Card theme
// =====================

//@import './styles/dummy-brands/iress_bank';
//@import '~@iress/client-portal-themes/dist/card/_variables';
//$fa-font-path: '~font-awesome/fonts'; /* Dev file path  */
//$roboto-font-path: '~roboto-fontface/fonts'; /* Dev file path  */
//@import './styles/_after-brand';
//@import '~@iress/client-portal-themes/dist/card/_core';
//@import './styles/theme-overrides/card/_theme';


// Dark theme
// =====================

//@import './styles/dummy-brands/dark';
//@import '~@iress/client-portal-themes/dist/dark/_variables';
//$fa-font-path: '~font-awesome/fonts'; /* Dev file path  */
//$roboto-font-path: '~roboto-fontface/fonts'; /* Dev file path  */
//@import './styles/_after-brand';
//@import '~@iress/client-portal-themes/dist/dark/_core';
//@import './styles/theme-overrides/dark/_theme';


// Sanlam theme
// =====================

//@import './styles/dummy-brands/sanlam';
//@import '~@iress/client-portal-themes/dist/client/sanlam/_variables';
//$fa-font-path: '~font-awesome/fonts'; /* Dev file path  */
//$roboto-font-path: '~roboto-fontface/fonts'; /* Dev file path  */
//@import './styles/_after-brand';
//@import '~@iress/client-portal-themes/dist/client/sanlam/_core';
//@import './styles/theme-overrides/client/sanlam/_theme';


// Pru theme
// =====================

//@import './styles/dummy-brands/pru';
//@import '~@iress/client-portal-themes/dist/client/pru/_variables';
//$fa-font-path: '~font-awesome/fonts'; /* Dev file path  */
//$roboto-font-path: '~roboto-fontface/fonts'; /* Dev file path  */
//@import './styles/_after-brand';
//@import '~@iress/client-portal-themes/dist/client/pru/_core';
//@import './styles/theme-overrides/client/pru/_theme';


// Cbam theme
// =====================

//@import './styles/dummy-brands/cbam';
//@import '~@iress/client-portal-themes/dist/client/cbam/_variables';
//$fa-font-path: '~font-awesome/fonts'; /* Dev file path  */
//$roboto-font-path: '~roboto-fontface/fonts'; /* Dev file path  */
//@import './styles/_after-brand';
//@import '~@iress/client-portal-themes/dist/client/cbam/_core';
//@import './styles/theme-overrides/client/cbam/_theme';


